import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroSection from "../components/heroSection"
import Carousel from "../components/Carousel"
import FitLabBrands from "../components/FitLabBrands"
import MediaRelations from "../components/MediaRelations"
import AsSeenOn from "../components/AsSeenOn"
import Cookies from "../components/cookies"
import "../scss/dashboard.scss"

import fitness1 from "../images/carousel/fitness-1.png"
import fitness2 from "../images/carousel/fitness-2.png"
import fitness3 from "../images/carousel/fitness-3.png"
import fitness4 from "../images/carousel/fitness-4.png"
import fitnessMobile1 from "../images/carousel/fitness-mobile-1.png"
import fitnessMobile2 from "../images/carousel/fitness-mobile-2.png"
import fitnessMobile3 from "../images/carousel/fitness-mobile-3.png"
import fitnessMobile4 from "../images/carousel/fitness-mobile-4.png"
import athlete1 from "../images/carousel/athlete-1.png"
import athlete2 from "../images/carousel/athlete-2.png"
import athlete3 from "../images/carousel/athlete-3.png"
import athlete4 from "../images/carousel/athlete-4.png"
import athlete5 from "../images/carousel/athlete-5.png"
import athlete6 from "../images/carousel/athlete-6.png"
import athlete7 from "../images/carousel/athlete-7.png"
import athleteMobile1 from "../images/carousel/athlete-mobile-1.png"
import athleteMobile2 from "../images/carousel/athlete-mobile-2.png"
import athleteMobile3 from "../images/carousel/athlete-mobile-3.png"
import athleteMobile4 from "../images/carousel/athlete-mobile-4.png"
import athleteMobile5 from "../images/carousel/athlete-mobile-5.png"
import athleteMobile6 from "../images/carousel/athlete-mobile-6.png"
import athleteMobile7 from "../images/carousel/athlete-mobile-7.png"
import electric from "../images/fitlab-brands/electric.png"
import fast from "../images/fitlab-brands/fast.png"
import fitplan from "../images/fitlab-brands/fitplan.png"
import lymbr from "../images/fitlab-brands/lymbr.png"
import ragnar from "../images/fitlab-brands/ragnar.png"
import spartanFit from "../images/fitlab-brands/spartan-fit.png"
import xpt from "../images/fitlab-brands/xpt.png"
import quote from "../images/quote.png"

const fitnessCarouselFields = [
  {
    title: "Train together in the next generation of boutique fitness studios.",
    imageSource: fitness1,
    imageSourceMobile: fitnessMobile1,
  },
  {
    title:
      "Turn your home into a state-of-the-art training facility with connected equipment.",
    imageSource: fitness2,
    imageSourceMobile: fitnessMobile2,
  },
  {
    title:
      "Challenge yourself with competitions, races, and events that put your training to the test. ",
    imageSource: fitness3,
    imageSourceMobile: fitnessMobile3,
  },
  {
    title: "Test, track, and transform on the go. ",
    imageSource: fitness4,
    imageSourceMobile: fitnessMobile4,
  },
]

const athleteCarouselFields = [
  {
    title: "Conor McGregor: The first two-division belt holder in UFC history ",
    imageSource: athlete1,
    imageSourceMobile: athleteMobile1,
  },
  {
    title:
      "<div>Gabby Reece: Beach volleyball legend" +
      "<div>Laird Hamilton: Big-wave surfing pioneer</div>",
    imageSource: athlete2,
    imageSourceMobile: athleteMobile2,
  },
  {
    title:
      "Michelle Lewin: Two-time NPC champion and international cover model",
    imageSource: athlete3,
    imageSourceMobile: athleteMobile3,
  },
  {
    title:
      "Jen Selter:  Social media icon and original mainstream fitness influencer ",
    imageSource: athlete4,
    imageSourceMobile: athleteMobile4,
  },
  {
    title: "John John Florence: Two-time World Surf League champion",
    imageSource: athlete5,
    imageSourceMobile: athleteMobile5,
  },
  {
    title:
      "Jason Momoa: Hollywood superstar (Game of Thrones, Aquaman, Dune)athlete and philanthropist",
    imageSource: athlete6,
    imageSourceMobile: athleteMobile6,
  },
  {
    title: "Mike O’Hearn:  Four-time Mr. Universe winner",
    imageSource: athlete7,
    imageSourceMobile: athleteMobile7,
  },
]

const fitlabBrands = [
  {
    title: "Electric",
    imageSource: electric,
    description:
      "Premier action sports & training brand providing Style that Performs.",
    link: "https://www.electriccalifornia.com",
  },
  {
    title: "McGregor FAST",
    imageSource: fast,
    description:
      "UFC Champion Conor McGregor is pulling back the curtain on the science behind FAST and giving you the tools to train like an elite competitor.",
    link: "https://www.mcgregorfast.com",
  },
  {
    title: "Fitplan",
    imageSource: fitplan,
    description:
      "Train with the best athletes and coaches on the planet--at the gym, at home or anywhere life  takes you.",
    link: "https://www.fitplanapp.com",
  },
  // {
  //   title: "Lymbr",
  //   imageSource: lymbr,
  //   description:
  //     "The first holistic wellness & readiness company, Lymbr, restores your body through personalized stretching, mobility & movement patterning, driving improvements in performance and well-being.",
  //   link: "https://belymbr.com",
  // },
  {
    title: "Ragnar",
    imageSource: ragnar,
    description:
      "Epic adventures and human connection.Trail, road and relay races through the most beautiful courses imaginable. Find your Wild.",
    link: "https://www.runragnar.com",
  },
  {
    title: "Spartan FIT",
    imageSource: spartanFit,
    description:
      "Train like a Spartan with the official Spartan Race training app. World class coaching, advice, and hundreds of workouts for peak performance on and off the course.",
    link: "https://www.spartan.com/pages/fit-app",
  },
  {
    title: "XPT | Extreme Performance Training",
    imageSource: xpt,
    description:
      "The world's most advanced performance training. Founded by Laird Hamilton & Gabby Reece, XPT's Breath. Move. Recover. system drives elite performance across all aspects of life.",
    link: "https://www.xptlife.com",
  },
]

const mediaRelations = [
  {
    date: "01 . 10 . 2021",
    title: "FitLab Press Kit 2021",
    label: "Google Drive",
    link: "https://bit.ly/3mUGc2r",
  },
  {
    date: "01 . 26 . 2021",
    title:
      "FITT INSIDER #67: Brian Kirkbride,<br/> Co-founder & Co-CEO of FitLab",
    label: "fittinsider.com",
    link: "https://insider.fitt.co/67-brian-kirkbride-co-founder-co-ceo-of-fitlab/",
  },
  {
    date: "05 . 22 . 2017",
    title:
      "Barbell Shrugged #161:How To Scale Up <br/> - Or Sit Tight- With Mike Melby",
    label: "youtube.com",
    link: "https://www.youtube.com/watch?v=BpXzqWW6NtI",
  },
]

const cookiesContent = {
  message:
    "Our site uses cookies so that we can remember you and understand how you use our site. If you do not agree with our use of cookies, please click 'Decline'. Otherwise, click 'Accept' to agree.",
  acceptLabel: "Accept",
  declineLabel: "Decline",
}

const IndexPage = () => (
  <Layout>
    <Seo title="FitLab Home" />
    <HeroSection />
    <p className="fitness-text">
      The first platform to connect every aspect of your fitness lifestyle,
      bringing immersive experiences to life whenever, wherever, and however you
      train.
    </p>
    <div className="fitness-carousel">
      <Carousel>
        {fitnessCarouselFields.map((item, index) => (
          <div key={index} className="carousel-slide">
            <div className="carousel-slide-image">
              <img
                className="desktop-slide"
                src={item.imageSource}
                alt={item.title}
              />
              <img
                className="mobile-slide"
                src={item.imageSourceMobile}
                alt={item.title}
              />
              <p className="carousel-slide-title">{item.title}</p>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
    <p className="athlete-text">
      Developed in partnership with iconic athletes who fuel your active
      lifestyle.
    </p>
    <div className="athlete-carousel">
      <Carousel>
        {athleteCarouselFields.map((item, index) => (
          <div key={index} className="carousel-slide small">
            <div className="carousel-slide-image">
              <img
                className="desktop-slide"
                src={item.imageSource}
                alt={item.title || `athlete-${index}`}
              />
              <img
                className="mobile-slide"
                src={item.imageSourceMobile}
                alt={item.title || `athlete-${index}`}
              />
              <p
                className="carousel-slide-title"
                dangerouslySetInnerHTML={{ __html: item.title }}
              />
            </div>
          </div>
        ))}
      </Carousel>
    </div>
    <div className="fitness-text">
      <div className="quote-image">
        <img src={quote} alt="quote" />
      </div>
      Fitlab has built an amazing portfolio of top tier brands and I'm a huge
      fan of the team. I can't wait to see what's next for them.
      <p>– Anthony Vennare, Fitt Insider</p>
    </div>
    <div className="fitness-brands">
      <p>As seen on</p>
    </div>
    <AsSeenOn />
    <div className="fitlab-brands">
      <p>FitLab Brands</p>
    </div>
    <FitLabBrands fitlabBrands={fitlabBrands} />
    <div className="media-relations">
      <p>Media Relations</p>
    </div>
    <MediaRelations mediaRelations={mediaRelations} />
    <Cookies content={cookiesContent} />
  </Layout>
)

export default IndexPage
